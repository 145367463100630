import React from 'react'

export default function PinterestIcon() {
  return (
    <span className="icon icon--pinterest">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#000">
        <path d="M11.985.06C5.379.06.024 5.414.024 12.02c0 5.067 3.152 9.398 7.601 11.14-.105-.946-.199-2.401.041-3.434.218-.933 1.403-5.945 1.403-5.945s-.358-.716-.358-1.776c0-1.663.964-2.904 2.164-2.904 1.02 0 1.514.766 1.514 1.684 0 1.027-.654 2.56-.991 3.983-.282 1.19.597 2.16 1.771 2.16 2.126 0 3.76-2.24 3.76-5.476 0-2.864-2.058-4.866-4.996-4.866-3.403 0-5.4 2.553-5.4 5.19 0 1.029.396 2.13.89 2.73a.357.357 0 01.083.343c-.091.378-.293 1.19-.333 1.356-.052.22-.173.266-.4.16-1.493-.695-2.427-2.879-2.427-4.633 0-3.773 2.741-7.238 7.902-7.238 4.15 0 7.373 2.957 7.373 6.908 0 4.122-2.599 7.44-6.206 7.44-1.212 0-2.351-.63-2.741-1.374 0 0-.6 2.283-.745 2.843-.27 1.039-1 2.34-1.487 3.135 1.12.347 2.309.534 3.542.534 6.605 0 11.96-5.355 11.96-11.96 0-6.606-5.355-11.96-11.96-11.96z"/>
      </svg>
    </span>
  )
}
