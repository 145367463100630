import React from 'react'

export default function TwitterIcon() {
  return (
    <span className="icon icon--twitter">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#000">
        <path d="M7.548 21.71c9.056 0 14.01-7.504 14.01-14.011 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 4.513a9.829 9.829 0 01-2.828.775 4.94 4.94 0 002.165-2.723A9.867 9.867 0 0120.21 3.76a4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.106 4.928 4.928 0 003.195 9.68 4.887 4.887 0 01.96 9.063v.063a4.926 4.926 0 003.95 4.826 4.917 4.917 0 01-2.223.085 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 19.496a13.94 13.94 0 007.548 2.208" />
      </svg>
    </span>
  )
}
