import { Link } from "gatsby";
import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';

const LinkToTopic = ({ name }) => {
  const slug = slugify(name, { lower: true });
  const to = `/articles/${slug}`;
  return (
    <Link
      to={to}
      className='topic'
    >
      {name}
    </Link>
  );
};

export default LinkToTopic;

LinkToTopic.propTypes = {
  name: PropTypes.string.isRequired,
};
