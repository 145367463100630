import React from 'react'

export default function FacebookIcon() {
  return (
    <span className="icon icon--facebook">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#000">
        <path d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.989 4.388 10.954 10.125 11.855V15.47H7.077V12h3.048V9.356c0-3.008 1.792-4.67 4.533-4.67 1.313 0 2.686.235 2.686.235v2.953h-1.513c-1.49 0-1.956.925-1.956 1.875V12h3.328l-.532 3.47h-2.796v8.385C19.612 22.955 24 17.99 24 12z" />
      </svg>
    </span>
  )
}
