import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import ArticleLayout from "@components/article-layout"
import ArticleLinkList from '@components/article-link-list'
import SEO from "@components/seo"
import LinkToTopic from "@components/link-to-topic"
import Salutation from "./salutation"
import ArticleShareLinks from "@components/article-share-links"
import { convertArticleNodeToArticleProp } from '@lib/util'

const articlesMatchingPaths = ({ paths, max, topic, allArticles }) => {
  var matchingArticles = [];
  paths.some((path) => {
    const articleEdgeForPath = allArticles.edges.find((articleEdge) => {
      return articleEdge.node.frontmatter.topics.indexOf(topic) !== -1 && articleEdge.node.fileAbsolutePath === path;
    });
    if (articleEdgeForPath !== undefined) {
      matchingArticles.push(convertArticleNodeToArticleProp(articleEdgeForPath.node));
    }
    return matchingArticles.length >= max;
  });
  return matchingArticles;
};

export default function ArticlePage({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) {
  const { article, allArticles } = data
  const { frontmatter, fields, html } = article
  const {
    title,
    subtitle,
    featuredImage,
    featuredImageAttribution,
    topics,
    canonicalUrl,
  } = frontmatter
  const topic = topics[0];
  const { urlPath, forceInApp } = pageContext
  const readingTimeMinutes = fields.readingTime.minutes
  const relatedArticles = articlesMatchingPaths({
    paths: article.fields.relatedFileAbsolutePaths || [],
    topic: topic,
    max: 100,
    allArticles: allArticles,
  });

  return (
    <ArticleLayout forceInApp={forceInApp}>
      <SEO
        title={title}
        description={subtitle}
        imageData={featuredImage?.childImageSharp?.seoImage}
        pathname={urlPath}
        canonicalUrl={canonicalUrl}
      />

      <article className="article">
        <div className="container">
          <div className="articleInfo">
            <div className="articleTopicsList">
              {topics && topics.map((topic, idx) => (
                <React.Fragment key={topic}>
                  <LinkToTopic name={topic} />
                  {idx < topics.length - 1 && (<span className="divider">&nbsp;|&nbsp;</span>)}
                </React.Fragment>
              ))}
            </div>

            <h1 className="articleTitle">{title}</h1>

            {subtitle && (
              <h2 className="subtitle">{subtitle}</h2>
            )}

            <span className="attribution">{Math.round(readingTimeMinutes)} minute read</span>
          </div>
        </div>

        {featuredImage && (
          <div className="container container--featuredImage">
            <GatsbyImage image={featuredImage.childImageSharp.headlineImage} alt="" />
          </div>
        )}
        {featuredImageAttribution && (
          <div className="container">
            <span className="attribution">Photo: {featuredImageAttribution}</span>
          </div>
        )}

        <div className="container">
          <ArticleShareLinks articleUrlPath={urlPath} />
        </div>

        <div className="container">
          <div
            className="articleMain"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <a className="u-url" href={urlPath} hidden>self</a>
      </article>
            
      <Salutation />

      {relatedArticles.length > 0 && (<div className="relatedArticles">
        <div className='container'>
          <div className="articlesListHeader">
            <h2 className="listTitle">Related Articles</h2>
          </div>
        </div>

        <ArticleLinkList articles={relatedArticles} />
      </div>)}

      {/*
        <div className="headline">
          <p><Link to="/">&#10229; Back to topics</Link></p>
          <h1 className="pageTitle">{title}</h1>
        </div>

        <div className="aside">
          <span>Last updated: </span>
          <time className="dt-published" dateTime={date} itemProp="datePublished">
            {displayDate}
          </time>
        </div>
      */}
    </ArticleLayout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.object,
    allArticles: PropTypes.arrayOf(PropTypes.object),
  }),
  pageContext: PropTypes.shape({
    urlPath: PropTypes.string.isRequired,
    forceInApp: PropTypes.bool,
  })
};

// Date format uses momentjs: https://momentjs.com/docs/#/displaying/format/
export const pageQuery = graphql`
  query($fileAbsolutePath: String!) {
    article: markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      html
      frontmatter {
        title
        subtitle
        displayDate: date(formatString: "MMM D, YYYY")
        date: date(formatString: "YYYY-MM-DD")
        featuredImage {
          childImageSharp {
            seoImage: gatsbyImageData(width: 1200)
            headlineImage: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        featuredImageAttribution
        topics
        canonicalUrl
      }
      fields {
        readingTime {
          minutes
        }
        relatedFileAbsolutePaths
      }
      parent {
        ... on File {
          name
        }
      }
    }
    allArticles: allMarkdownRemark {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            title
            subtitle
            topics
            displayDate: date(formatString: "MMM D, YYYY")
            featuredImage {
              childImageSharp {
                thumbnailImage: gatsbyImageData(width: 300)
              }
            }
          }
          fields {
            readingTime {
              minutes
            }
          }
          parent {
            ... on File {
              name
              relativeDirectory
            }
          }
        }
      }
    }
  }
`
