import React from 'react'
import PropTypes from "prop-types"
import encodeUrl from 'encodeurl';
import OffSiteLink from '@components/off-site-link'
import FacebookIcon from '@components/facebook-icon'
import TwitterIcon from '@components/twitter-icon'
import PinterestIcon from '@components/pinterest-icon'
import useSiteMetadata from "@components/hooks/use-site-metadata"

export default function ArticleShareLinks({ articleUrlPath }) {
  const { siteUrl } = useSiteMetadata();
  const shareUrl = encodeUrl(`${siteUrl}${articleUrlPath}`);

  return (
    <div className="articleShareLinks">
      <span className="articleShareLinks--prompt">Share</span>
      <OffSiteLink href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}>
        <FacebookIcon />
      </OffSiteLink>
      <OffSiteLink href={`https://twitter.com/intent/tweet?url=${shareUrl}`}>
        <TwitterIcon />
      </OffSiteLink>
      <OffSiteLink href={`https://pinterest.com/pin/create/button/?url=${shareUrl}`}>
        <PinterestIcon />
      </OffSiteLink>
    </div>
  )
}

ArticleShareLinks.propTypes = {
  articleUrlPath: PropTypes.string.isRequired,
};
